import './BindMain.css';
import {useState} from "react";
import {request} from "../request";

export const BindMain = () => {

    const [greenPlatformAccountNo, setGreenPlatformAccountNo] = useState([]);

    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("message");

    function conformGreenPlatformAccount(e) {

        setMessage('帐号绑定中...')

        request.post('/api/setGreenPlatformAccount', {}, {
            params: {
                greenPlatformAccountNo
            }
        }).then((response) => {
            const {code, message} = response.data;
            if (code === 4096) {
                setMessageClass('message success-message')
            } else {
                setMessageClass('message error-message')
            }
            setMessage(message)
        })
    }

    return (
        <div className={'bind-main'}>
            <div style={{paddingLeft: '10px', paddingRight: '10px', boxSizing: 'border-box'}}>
                <span className={'title'}>请输入您的绿色账户</span>
                <div>
                    <input type={"number"} className={'bind-input'}
                           onChange={e => setGreenPlatformAccountNo(e.target.value)}/>
                </div>
            </div>
            <span className={messageClass}>{message}</span>
            <div style={{position: 'absolute', bottom: 0, width: '100%', textAlign: 'center'}}>
                <button className={'bind-conform-btn'} onClick={conformGreenPlatformAccount}>确认绑定绿色帐号</button>
            </div>
        </div>
    )
}