import './App.css';
import {BindMain} from "./pages/BindMain";

function App() {
    return (
        <BindMain/>
    );
}

export default App;
